import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { allAppSetup } from "../appSetupSlice";
import { allStrings } from "../stringsSlice";
import { setSearchData, setSelectedTripData, webSalesTripData } from "../web_salesSlice";
import { TitleSelectTextt, TitleSmall } from "./Headers";

import moment from 'moment'

const SelectComponent = () => {
    const dispatch = useDispatch();
    const strings = useSelector(allStrings)
    const appSetup = useSelector(allAppSetup)
  const tripData = useSelector(webSalesTripData);
  
  const handleSelect = (e, row) => {
    console.log(row);
    dispatch(setSelectedTripData({ path: "selected_trip", value: row }));
  };

  const handleOneWayTrip = () => {
    dispatch(setSearchData({ path: "return_trip", value: false }));
  };

  const handleReturnTrip = () => {
    dispatch(setSearchData({ path: "return_trip", value: true }));
  };

  const dateDa = '02.10.2023 07:35'
  const dateDEparture = new Date(dateDa)

  const formatDate = (dateDALA) => {
    console.log(dateDALA)
    console.log(dateDa)
    const dateD = String(dateDALA)
    if(dateD){
    const dateENG = moment(dateDa.slice(0, 10)
        + '.' + dateDa.slice(10)).format(appSetup.dates.datetime_format.eng)
        console.log(dateENG)
    const dateCRO = moment(dateD.slice(0, 10)
        + '.' + dateD.slice(10)).format(appSetup.dates.datetime_format.cro)
        console.log(dateCRO)
      }else{
        console.log('nije odabran datum')
      }
  }


  return (
    <>
    {tripData.trips ? (
      <Grid container direction="row" sx={{ mt: 3 }}>
        {tripData.trips.length ? (
          <>
            <Grid item xs={12}>
            <Grid item>
              <TitleSmall from={tripData.searchData.travel_from.harbor_name} to={tripData.searchData.travel_to.harbor_name} />
              <TitleSelectTextt text1={strings.selectText[appSetup.language]}  />
            </Grid>
              <Grid>
                <TableContainer component={Paper}>
                  <Table aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{strings.departure[appSetup.language]}</TableCell>
                        <TableCell>{strings.arrival[appSetup.language]}</TableCell>
                        <TableCell
                           sx={{
                            display: { xs: 'none', sm:'block'}
                          }}
                        >{strings.seats[appSetup.language]}</TableCell>
                        <TableCell align="right">{strings.price[appSetup.language]}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tripData.trips.map((row) => (
                        <TableRow
                          className='select'
                          key={row.id}
                          onClick={(e) => handleSelect(e, row)}
                          selected={tripData.selected_trip.id === row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:hover": { cursor: 'pointer' }
                          }}
                        >
                          <TableCell component="th" scope="row" sx={{fontWeight:'bold'}}>
                          {row.departure}
                          </TableCell>
                          <TableCell>{row.arrival}</TableCell>
                          <TableCell
                            sx={{
                              display: { xs: 'none', sm:'block'}
                            }}
                          >500</TableCell>
                          <TableCell align="right">200</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={6}></Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Box
              fullwidth="true"
              sx={{
                bgcolor: "background.paper",
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                height: 40,
              }}
            >
              {!tripData.trips.length ? (
                <> Za Navedeni termin nema dostupnih polazaka</>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sx={{mt:2}}>
          
        </Grid>
      </Grid>
      ) : ''}
    </>
  );
};

export default SelectComponent;
