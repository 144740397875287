import { ButtonGroup, Button, Grid, Paper, Box } from "@mui/material";
import { Title } from "./Headers";
import { useSelector } from "react-redux";
import { webSalesStingsSummaryPage, webSalesStatusesData } from "../web_salesSlice";
import { useState } from "react";
import { useEffect } from "react";
import { allStrings } from "../stringsSlice";
import { allAppSetup } from "../appSetupSlice";

import picture from '../../../img/KRILO_ECLIPSE.png'

const SummaryEmptyComponent = () => {
    return (
      <>
         <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  src={picture}
                  style={{ cursor: "pointer", borderRadius: "5%",width:'100%' }}
                />
            </Box>
      </>
  );
};

export default SummaryEmptyComponent;
